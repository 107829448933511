import "./Ordermbcase.css";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Productdetails from "../../Component/Productdetails";
import hand from "../../assets/images/bghand.png";

import mbcs2 from "../../assets/images/mbcs2.png";
// import ice from "../../assets/images/ad.jpg";
 
function Ordermbcase() {

    return (
       
        <div class="container-fluid my-5">
            <div class="row row-cols-1 row-cols-lg-2 g-2 g-lg-3">
                <div class="col">
                    <div class=" border h-100">
                    {/* <h1>Who lives in my garage?</h1> */}
                        {/* <img src={hand}  className="hand-img"/>
                        <div class="mock">
                        <img class="mock-img" src={mbcs2} />
                        </div> */}
                        {/* className="user-demo"  */}
                    <div className="demo h-100">
                        <div class="sel">
                        <img  src={mbcs2} className="user-demo" />
                        </div>
                    </div>
                    {/* <div class="h-100 bg-danger demo">
                    <img src={hand} className="admin-demo"/>
                    <div class="mockdiv">
                    <img  src={mbcs2} className="user-demo" />
                    </div>
                    </div> */}
{/* <img class="previewImg rounded-4 img-fluid w-100" id="previewPage1" width="336" height="364" src={hand}></img> */}
{/* <img class="previewImg rounded-4 img-fluid w-100" id="previewPage1" width="336" height="364" src={hand}/> */}
                    </div>
                </div>
                <div class="col">
                    <div class="p-3 border ">
                        <p style={{ fontSize: 18 }}>Our Customer Reviews On Google</p>
                        <h2 >CUSTOM HARD CASES</h2>
                        <p style={{ fontSize: 18 }}>Availability: <span style={{ color: "red" }}>In Stock</span></p>
                        <hr></hr>
                        <h2 style={{ color: "red" }}>HIGHLIGHTS</h2>
                        <ul>
                            <li>
                                Multi-Color Printing
                            </li>
                            <li>Matte Finish</li>
                            <li>Polymer Hard Plastic Case</li>
                            <li>Free Shipping</li>
                        </ul>
                        <hr></hr>
                       
                        <div class="d-lg-flex d-sm-block">
                            <p class=" mt-3 " style={{ fontSize: 20, textDecoration: "line-through", }}>Rs.299</p>
                            <p class="mx-0 mx-lg-3" style={{ fontSize: 35, color: "green" }}>Rs.499</p>
                            <p class=" mt-3" style={{ fontSize: 20 }}>Free Shipping</p>
                        </div>
                        <hr></hr>

                        <button class="butn">
                            Add to Cart
                        </button>
                        <hr></hr>
                        <div >
                            <p class="text-center">
                                Check delivery at Location
                            </p>

                            <div class="input-group my-2">
                                <input type="text" class="form-control" placeholder="Enter Pincode" aria-label="Recipient's username" aria-describedby="button-addon2" />
                                <button class="btn btn-outline-secondary" type="button" id="button-addon2">Check</button>
                            </div>
                            <div></div>
                            <div class=" my-3 row row-col-3 d-lg-flex d-block text-center text-lg-start">
                                <div class="col">
                                    Ordered Date
                                    <p>dec 4</p>
                                </div>
                                <div class="col ">
                                    Printing and processing
                                    <p>dec 4-dec 5</p>
                                </div>
                                
                                <div class="col">
                                    Est. Delivery Date
                                    <p>dec 8-dec 9</p>
                                </div>
                            </div>
                            <div style={{ textIndent: "30px" ,textAlign:"justify"}}>
                            *Customized product process involves: printing, cutting, finishing, QC and making
                            the product ready to ship
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <hr></hr>
            <div >
        <Container fluid class="mx-auto my-5">
          <Row>
            <Col xs={12} lg={4} md={4} sm={12}>
              <p class="mbcspara m-2">10% CASH BACK</p>
            </Col>
            <Col xs={12} lg={4} md={4} sm={12}>
              <p class="mbcspara m-2">DESIGNS 400+</p>
            </Col>
            <Col xs={12} lg={4} md={4} sm={12}>
              <p class="mbcspara m-2"> MODELS 1500+</p>
            </Col>
          </Row>
        </Container>
        <div>
            <Productdetails/>
        </div>
      </div>
        </div>
    );
}
export default Ordermbcase;