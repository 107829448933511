import "./Sign.css";


function Sign() {
  return (
    <div className=" p-5 Signcontainer">
      <form className="Signform">
        <h1 className="snclr">Register</h1>
        <div class="form-group my-3 ">
          <br></br>
          <input class="snlbl sninptfnt" type="email" placeholder="Enter Your Name" />
          <br></br>
        </div>
        <div class="form-group my-3 ">
          <br></br>
          <input class="snlbl sninptfnt" type="email" placeholder="Enter phonenumber" />
          <br></br>
        </div>

        <div class="form-group my-3 ">
          <br></br>
          <input class="snlbl sninptfnt" type="email" placeholder="Enter email" />
          <br></br>
        </div>
        <div class="form-group my-3">
          <br />
          <input class="snlbl sninptfnt" type="password" id="exampleInputPassword1" placeholder="Password" />
        </div>
        <center><button type="submit" class="snbut my-3">Signin</button></center>
      </form>
    </div>
  );
}
export default Sign;