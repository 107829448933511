import imaging from "../assets/images/printshopy.png";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./Banner.css";
import imag from "../assets/images/printshopy3.png";
import imag1 from "../assets/images/prntshopy1.png";
import AOS from 'aos';

import { useEffect } from 'react';
function Banner() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div>
      <Container fluid>
        <Row>
          <Col>
            {/* <img className="image1" src={imaging} alt="banner" /> */}
            <p className="h1fnt">print<span style={{color:"red"}}>shopy</span></p>
          </Col>
        </Row>
        <Row>
          <Col>
            <img data-aos="zoom-in" className="image" src={imag1} alt="banner" />
          </Col>
        </Row>
        <Row>
          <Col>
            <img data-aos="zoom-in" className="image" src={imag} alt="banner" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Banner;