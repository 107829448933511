import Softcase from "../../Component/Softcase";
import Hardcase from "../../Component/Hardcase";
import Glasscase from "../../Component/Glasscase";
import "./Casetype.css";

function Casetype() {
    return (
        <div class="container-fluid my-5">
            <div class="text-center">
                <h1 class="section-header">Mobile Model Name</h1>
                <hr></hr>
                <p>Select Mobile Case Type</p>
            </div>
            <div class="row row-cols-1 row-cols-lg-3 g-2 ">
                <Glasscase />
                <Hardcase />
                <Softcase />
            </div>
            <div class=" col-12 text-center container-fluid my-3">
                <p>Can't Find Your Desired Model Type ?<a herf="#" class="sty1a">Let Us Know</a>
                </p>
            </div>
        </div>
    );
}
export default Casetype;