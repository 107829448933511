import Ourproduct from "../../Component/Ourpoduct";
import Version from "../Mobileversion/Vesion";

function Versiontype() {
  return (
    <div class="container-fluid w-full">
      <div class="row ">
        <div class="col-sm-12 col-md-4 col-lg-2  stick">
          <Ourproduct />
        </div>
        <div class="col-sm-12 col-md-8 col-lg-10 column">
          <Version />
        </div>
      </div>
    </div>
  )
}
export default Versiontype;