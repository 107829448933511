import React, { useState } from "react";
import background from "../assets/images/camera.jpg";
import "./Video.css";
import videoimg from "../assets/images/vdoimg.png";


function Video() {
  const [isVideoPopupOpen, setVideoPopupOpen] = useState(false);

  const openVideoPopup = () => {
    setVideoPopupOpen(true);
  };

  const closeVideoPopup = () => {
    setVideoPopupOpen(false);
  };

  return (
    <div className="parent cont">
      <img className="bg1" src={background} alt="hai" />
      <div className="child ">
        <h4 className="videofnt">PLEASE WATCH OUR PRODUCT DESIGNS</h4>
        <img className="size" onClick={openVideoPopup} src={videoimg} alt="hai" />

        {isVideoPopupOpen && (<div className="video-popup">
          <span className="close-icon " onClick={closeVideoPopup}>
            &#x2716;
          </span>
          <div class="tutorial container text-center my-5 ratio ratio-16x9">
            <iframe className="" src="https://www.youtube.com/embed/qgInM6FH8Lk?rel=0"
              allowfullscreen>
            </iframe>
          </div>
        </div>)}
      </div>
      <br></br>
      <br></br>
    </div>

  );
}

export default Video;

