import "./Ourproduct.css";
import { useState } from 'react';
import images3 from "../assets/images/ps.jpg";

function Ourproduct() {

  const product =
    [

      "Mobile case",
      "Cuboid Chains",
      "Sipper Bottles",
      "Illusion Lamps",
      "Snapbooks",
      "Wall Decoratives",
      "Photo Stands",
      "Mugs",
      "T-Shirts",
      "Pop Sockets",
      "Fridge Magnets",
      "Office Stationary"
    ]

  const [searchTerm, setSearchTerm] = useState('');

  const filteredProduct = product.filter(product =>
    product.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [isShown, setIsShown] = useState(true);
  const Display = () => {

    setIsShown(current => !current);

  }
  return (
    <div>
      <div class="arrow bounce st ">
        <a onClick={Display} class="fa fa-arrow-down fa-2x style" href="#"></a>
      </div>
      <img className="img1" src={images3} alt="hai" />

      {isShown ? (<div className="product">
        <ul>
          <div class="search-box">
            <button class="btn-search"><i class="fa fa-search"></i></button>
            <input value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)} type="text" class="input-search" placeholder="Type to Search..." />
          </div>
          {filteredProduct.map((product, index) => (
            <li className="productfnt productlst" key={index}>{product}</li>
          ))}
        </ul>
      </div>) : (null)}
      <br></br>
      <br></br>
    </div>

  );
}
export default Ourproduct;