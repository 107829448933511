import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "./Ourvision.css";

function Ourvision() {
  return (

    <div className='container-fluid my-3'>
      <div className='row'>
        <div className='col col-lg-8 col-md-12 col-sm-12 col-xs-12 mx-auto '>
          <div className='borderbg-light p-2 '>
            <h1 class="section-header my-3">Corporate gifting</h1>
            <p style={{textAlign:"justify"}}>
              Our mission is to provide something for everyone, and we have something to suit everyone's needs. Our Memorable Gifts will make your employees, clients, distributors, and partners feel appreciated and valued.
            </p>
            <p style={{textAlign:"justify"}}> <span style={{color:"green"}}>Note:</span> Contact us for information regarding corporate gifting</p>
            <div className="text-end">
              <button className="cta p-2">
                <span>Contact Us</span>
                <svg viewBox="0 0 46 16" height="10" width="30" xmlns="http://www.w3.org/2000/svg" id="arrow-horizontal">
                  <path transform="translate(30)" d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z" data-name="Path 10" id="Path_10"></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Container fluid class="mx-auto my-5" >
        <Row >
          <Col xs={12} lg={4} md={4} sm={12}>
            <p class="para1 m-2">30-days return policy</p>
          </Col>
          <Col xs={12} lg={4} md={4} sm={12}>
            <p class="para1 m-2">express delivery</p>
          </Col>
          <Col xs={12} lg={4} md={4} sm={12}>
            <p class="para1 m-2">100% quality assured</p>
          </Col>
        </Row>
      </Container>
    </div>


  );
}
export default Ourvision;