import "./Version.css";
import Steps from "../../Component/Steps";
import { useNavigate } from "react-router-dom";

function Version() {
    const navigate = useNavigate();
    return (
        <div class="container-fluid w-full my-5">
            <div class="row ">
                <div class="col-sm-12 col-md-4 col-lg-12 text-center">
                    <h2 class="my-4">Apple</h2>
                    <p class="my-4">Order Your Smar Phone Case in 3 steps</p>
                </div>
                <div>
                    <Steps />
                </div>
                <div class="row row-cols-1 row-cols-lg-3 row-cols-sm-2 my-4">
                    <div class="col  ">
                        <div class="p-3 shadow text-center">
                            <a href="#">
                                <p className="fs-3"onClick={() => navigate("/Mbcasetype")}>Apple</p>
                            </a></div>
                    </div>
                    <div class="col  ">
                        <div class="p-3 shadow text-center">
                            <a href="#">
                                <p className="fs-3" >Apple</p>
                            </a></div>
                    </div>
                    <div class="col  ">
                        <div class="p-3 shadow text-center">
                            <a href="#">
                                <p className="fs-3">Apple</p>
                            </a></div>
                    </div>
                    <div class="col  ">
                        <div class="p-3 shadow text-center">
                            <a href="#">
                                <p className="fs-3">Apple</p>
                            </a></div>
                    </div>
                </div>


            </div>
            <div class=" col-12 text-center container-fluid my-3">
                <p>Can't Find Your Model?<a herf="#" class="stya">Let Us Know</a>
                </p>
            </div>

            {/* <div><h1 class="section-header">More Info</h1></div> */}
        </div>
    )
}
export default Version;