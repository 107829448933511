import "./Verification.css";


function Verification(){
    return(

        <div>
            <h1>
                hai
            </h1>
        </div>
    );

}

export default Verification;