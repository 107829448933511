import "./Cscarddesign.css";
import csdsn from "../assets/images/mbcs.png";
import { useNavigate } from "react-router-dom";

function Cscarddesign() {
    const navigate = useNavigate();

    return (
        <div class="col">
            <div class="card casesize" >
                <div class="caseimgsize">
                    <img class="card-img-top caseimg" src={csdsn} alt="Card image cap" />
                </div>
                <button class="butcscrd m-auto" onClick={() => navigate("/Upload")}>
                    Start Design
                </button>
            </div>
        </div>
    );
}
export default Cscarddesign;