// import "./Mobilemodel.css";
import "./Offer.css";
import { Card } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import offer from "../assets/images/offer.jpg";
// import Offer from "../Component/Offer";


function Offer(){
    return(
        <div className="container-fluid mt-40">
        <h1 class="section-header my-5">OFFERS</h1>
        <Container fluid   >
          <Row >
            <Col xs={12} lg={6} md={12} sm={12} className="crd" >
              <Card >
                <Card.Header>
                  <h1>50% off</h1>
                </Card.Header>
                <Card.Img class="offer" variant="top" src={offer} />
                <Card.Body>
                  <Card.Title > BUY ANY 2 MOBILES  CASE 50% OFF
                  </Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make up the
                    bulk of the card's content.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    )
}
export default Offer;