import "./Socialmedia.css";

function Socialmedia() {
    return (
        <div className="social">
            <i class="bi bi-instagram"></i>
            <i class="bi bi-twitter"></i>
            <i class="bi bi-whatsapp"></i>
        </div>
    );
}
export default Socialmedia;