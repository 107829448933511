import Ourproduct from "../../Component/Ourpoduct";
// import Socialmedia from "../../Component/Socialmedia";
import Mobilemodel from "../Mobilecase/Mobilemodel";
import Mobilebrand from "../Mobilecase/Mobilebrand";


function Mobile() {
  return (
    <div class="container-fluid w-full">
      <div class="row ">
        <div class="col-sm-12 col-md-4 col-lg-2  stick">
          <Ourproduct />
        </div>
        <div class="col-sm-12 col-md-8 col-lg-10 column">
          {/* <Socialmedia /> */}
          <Mobilebrand />
          <Mobilemodel />
          
        </div>
      </div>
    </div>
  );
}
export default Mobile;