import "./Footer.css";
import gpay from "../assets/images/gpay1.jpg";
import paytm from "../assets/images/paytm.jpg";
import phonepay from "../assets/images/phpay.jpg";


function footer() {
  return (

    <div>
      <hr></hr>
      <div >
    <h1 >100% SECURE PAYMENTS</h1>
    <div className="logos">
          {/* <img src={gpay}  alt="PhonePe Logo" /> */}
          {/* <img src={paytm} alt="GPay Logo" /> */}
          {/* <img src={phonepay} alt="Paytm Logo" /> */}
        </div>
  </div>
    <div className="container-fluid bg-dark " >
<div class="container  text-center py-5">

  <div class="row row-cols-1 row-cols-lg-4 row-cols-md-4  ">
          <div class="col">
          <h3 className="text-white">Company</h3>
            <ul className="list-unstyled">
              {/* <li><a href="#">About Us</a></li> */}
              <li><a href="#about">AboutAs</a></li>
              <li><a href="#about">Privacy Policy</a></li>
              <li><a href="#contact">T&C's</a></li>
              <li><a href="#contact">Refer & Earn</a></li>
            </ul>
            </div>


    <div class="col">
      <h3 className="text-white">Best Sellers</h3>
            <ul className="list-unstyled ">
              <li><a href="#about">Wall Photo Frames</a></li>
              <li><a href="#about">Photo Stands</a></li>
              <li><a href="#contact">Mobile Cases</a></li>
              <li><a href="#contact">Photo Mugs</a></li>
            </ul>
    </div>
    <div class="col">
      
      <h3 className="text-white">Support</h3>
            <ul className="list-unstyled">
              <li><a href="#about">Contact Us</a></li>
              <li><a href="#about">Track Order</a></li>
              <li><a href="#contact">Return Order</a></li>
              <li><a href="#contact">FAQ's</a></li>
            </ul>
     
    </div>
    <div class="col">
       <h3 className="text-white">More Info</h3>
            <ul className="list-unstyled">
              <li><a href="#about">My Account</a></li>
              <li><a href="#about">Order History</a></li>
              <li><a href="#contact">Your Credits</a></li>
            
            </ul>
    </div>
   
   
   
  </div>
</div>
    </div>
    </div>
    // <div className='container-fluid foot'>
    //   <div className='row'>
    //     <div className='col-sm-4 text-white text-center'>
    //       <h5>About</h5>
    //       <div className='lnk1'> Scanfcode.com <i>CODE WANTS TO BE SIMPLE </i> is an initiative  to help the upcoming programmers with the code. Scanfcode focuses on providing the most efficient code or snippets as the code wants to be simple. We will help programmers build up concepts in different programming languages that include C, C++, Java, HTML, CSS, Bootstrap, JavaScript, PHP, Android, SQL and Algorithm.</div>
    //     </div>
    //     <div className='col-sm-4 text-center text-white '>
    //       <h5>Quick Links</h5>
    //       <p>
    //         Home
    //       </p>
    //       <p>
    //         About
    //       </p>
    //       <p>
    //         contact
    //       </p>
    //     </div>
    //     <div className='col-sm-4 text-white text-center'>
    //       <h5>Contact</h5>
    //       www.ask12@gmail.com<br></br>
    //       USA:+12-87934<br></br>
    //       PH:+91 7845419317
    //     </div>
    //   </div>
    //   <hr className='text-white'></hr>
    //   <div><h6 className='text-white p-2 carop headernav'>Copyright @{" "} {new Date().getFullYear()}.{"  "}All reserved by www.virtualtechnology.in</h6>
    //   </div>
    // </div>
  );
}
export default footer;