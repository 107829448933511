import "./Softcase.css";

function Softcase() {
  return (
    <div className="col">
      <div class="card" >
        <div class=" embed-responsive embed-responsive-16by9">
          <iframe class="vdifrmsc embed-responsive-item" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" allowfullscreen></iframe>
        </div>
        <div class="card-body">
          <h5 class="card-title">SOFT CASE</h5>
          <p class="card-text">@ Just RS 399/-</p>

          <a href="#" class="btn btn-primary">Shop Now</a>
        </div>
      </div>
    </div>
  )
}
export default Softcase;