import { BrowserRouter, Routes, Route } from "react-router-dom";
// import ScrollToTopRoute from './ScrollToTopRoute';
// import ScrollToTop from "../src/Scroll";
import Home from "../src/Home/Printshopy";
import Footer from "../src/Component/Footer";
import "./App.css";
import Mobile from "../src/Mobilecasefile/Mobilecase/Mobile";
import Head from "../src/Component/Head";
import Login from "../src/Pages/Login";
import Sign from "../src/Pages/Sign";
import Verification from "../src/Pages/Verification";
import Upload from "../src/Mobilecasefile/uploadimage/Upload";
import Mbcasetype from "../src/Mobilecasefile/Mobilecasetype/Mbcasetype";
import Versiontype from "../src/Mobilecasefile/Mobileversion/Versiontype";
import Csdesigntype from "../src/Mobilecasefile/Casedesigns/Csdesigntype";
import Ordercase from "../src/Mobilecasefile/Ordermbcase/Ordercase";
import Socialmedia from "../src/Component/Socialmedia";


function App() {
  return (

    <BrowserRouter> 
    {/* <Scroll/> */}
    {/* <ScrollToTop> */}
      <Routes>
        <Route path="/"
          element={<>
            <Head />
            <Socialmedia/>
            <Home />
            <Footer />
          </>
          }>
        </Route>

        <Route path="Mobile"
          element={<>
            <Head />
            <Mobile />
            <Footer />
          </>
          }>
        </Route>

        <Route path="Login"
          element={<>
            <Login />
          </>
          }>
        </Route>

        <Route path="Sign"
          element={<>
            <Sign />
          </>
          }>
        </Route>

        <Route path="Verification"
          element={<>
            <Verification />
          </>
          }>
        </Route>

        <Route path="Upload" element={<>
          <Head />
          <Upload />
          <Footer />
        </>}>
        </Route>

        <Route path="Mbcasetype" element={<>
          <Head />
          <Mbcasetype />
          <Footer />
        </>}>
        </Route>


        <Route path="Versiontype" element={<>
          <Head />
          <Versiontype />
          <Footer />
        </>}>
        </Route>


        <Route path="Csdesigntype" element={<>
          <Head />
          <Csdesigntype />
          <Footer />
        </>}>
        </Route>


        <Route path="Ordercase"
          element={<>
            <Head />
            <Ordercase/>
            {/* <Mobile /> */}
            <Footer />
          </>
          }>
        </Route>


        <Route path="Version" element={<>
          <Head />
        </>}>
        </Route>
      </Routes>
      {/* </ScrollToTop> */}
    </BrowserRouter>

  );
}

export default App;
