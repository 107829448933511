import "./Mobilemodel.css";
import mb from "../../assets/images/redbg.jpg";
import Accordion from 'react-bootstrap/Accordion';
import { Card } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import offer from "../assets/images/offer.jpg";
import Steps from "../../Component/Steps";
import Offer from "../../Component/Offer";



function Mobilemodel() {
  return (
    <div>

      <h1 class="my-5 section-header">
        Custom Mobile Cases
      </h1>

      <div >
        <Container fluid class="mx-auto my-5">
          <Row>
            <Col xs={12} lg={4} md={4} sm={12}>
              <p class="para m-2">10% CASH BACK</p>
            </Col>
            <Col xs={12} lg={4} md={4} sm={12}>
              <p class="para m-2">DESIGNS 400+</p>
            </Col>
            <Col xs={12} lg={4} md={4} sm={12}>
              <p class="para m-2"> MODELS 1500+</p>
            </Col>
          </Row>
        </Container>
      </div>
      <hr></hr>

        <div class="container-fluid">
          <Steps/>
        </div>

      
      
<hr></hr>

     <Offer/>

      <hr></hr>

     
      <div className="faq"><Accordion defaultActiveKey="0" flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header><h3>FAQ'S</h3></Accordion.Header>
          <Accordion.Body>
            <h3>What Should I do, If mobile Case gets Dirty or Dusty?</h3>
            <p>You can wash the case with soap water and clean it with a soft cloth and reuse the mobile cover as you use it before.</p>
            <h3>Does The Print Peel Off?</h3>
            <p>No, the print on the mobile back cover will never peel off. You can ask for refund, if the print peels off.
            </p>
            <h3>Do I Get Access to All ports and Buttons?</h3>

            <p>Yes, our mobile cover has access to all standard buttons and ports.</p>

            <h3>Which Type of Finish the Mobile Cover has?</h3>

            <p>We offer Matte Finish to All Mobile Covers we made.</p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion></div>
      <hr></hr>

    </div>
  );
}
export default Mobilemodel;