import "./Login.css";


function Login(){
   return(
<div className=" p-5 logincontainer">
<form className="loginform">
    <h1 className="clr">Login</h1>
  <div class="form-group my-4 icnpt">
    <br></br>
    <i class="fa fa-envelope icon icn"></i>
    <input class="lbl inptfnt" type="email"  placeholder="Enter email"/>
    <br></br>
  </div>
  <div class="form-group my-4 icnpt">
    <br/>
    <i class="fa fa-key icon icn"></i>
    <input class="lbl inptfnt" type="password"  id="exampleInputPassword1" placeholder="Password"/>
  </div>
  <p className="lgnp float-right">don't have an account? <span class="lnk">Login</span></p>
  <div className="my-2">
 
  </div>
  <br></br>
  <div class="text-center my-4">
      {/* <button type="submit" class="btn my-3">Login</button> */}
      <button type="submit" class="but my-3 text-center">Login</button>
    </div>
 {/*  */}
</form>
</div>
   );
}
export default Login;