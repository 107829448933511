import "./Mobilebrand.css";
import { useNavigate } from "react-router-dom";


function Mobilebrand() {
  const navigate = useNavigate();
  return (
    <div class="my-5">
      <div class="container-fluid ">
        <div>
          <p className="text-center">search your mobile model</p>
          <input className="sear" type="text" placeholder="Search your mobile model" name="search" />
        </div>
        <h1 class="section-header my-5">POPULAR BRANDS</h1>
        <div class="row row-cols-2 row-cols-lg-6 row-cols-md-3 g-2 ">
          <div class="col  ">
            <div class="p-3 shadow text-center">
            {/* <button
                  className="btn reserve__btn mt-4"
                  onClick={() => navigate("/usedcarvalidation")}
                >
                  Used Car Valuation
                </button> */}
              <a href="#">
                <p className="pfnt fs-3"  onClick={() => navigate("/Versiontype")}>Apple</p>
              </a></div>
          </div>
          <div class="col">
            <div class="p-3 shadow text-center">
              <a href="#">
                <p className="pfnt fs-3">Samsung</p>
              </a></div>
          </div>
          <div class="col  ">
            <div class="p-3 shadow text-center">
              <a href="#">
                <p className="pfnt fs-3">Oneplus</p>
              </a></div>
          </div>
          <div class="col  ">
            <div class="p-3 shadow text-center">
              <a href="#">
                <p className="pfnt fs-3">Redmi</p>
              </a></div>
          </div>
          <div class="col  ">
            <div class="p-3 shadow text-center">
              <a href="#">
                <p className="pfnt fs-3">Mi</p>
              </a></div>
          </div>
          <div class="col  ">
            <div class="p-3 shadow text-center">
              <a href="#">
                <p className="pfnt fs-3">Poco</p>
              </a></div>
          </div>

          <div class="col  ">
            <div class="p-3 shadow text-center">
              <a href="#">
                <p className="pfnt fs-3">Oppo</p>
              </a></div>
          </div>

          <div class="col  ">
            <div class="p-3 shadow text-center">
              <a href="#">
                <p className="pfnt fs-3">Vivo</p>
              </a></div>
          </div>

          <div class="col  ">
            <div class="p-3 shadow text-center">
              <a href="#">
                <p className="pfnt fs-3">Renoo</p>
              </a></div>
          </div>

          <div class="col  ">
            <div class="p-3 shadow text-center">
              <a href="#">
                <p className="pfnt fs-3">IQOO</p>
              </a></div>
          </div>

          <div class="col  ">
            <div class="p-3 shadow text-center">
              <a href="#">
                <p className="pfnt fs-3">Xiaomi</p>
              </a></div>
          </div>

          <div class="col  ">
            <div class="p-3 shadow text-center">
              <a href="#">
                <p className="pfnt fs-3">Infinix</p>
              </a></div>
          </div>

          <div class="col  ">
            <div class="p-3 shadow text-center">
              <a href="#">
                <p className="pfnt fs-3">Tecno</p>
              </a></div>
          </div>

          <div class="col  ">
            <div class="p-3 shadow text-center">
              <a href="#">
                <p className="pfnt fs-3">Google</p>
              </a></div>
          </div>

        </div>
      </div>
      <hr></hr>
      <div class="container-fluid ">
        <h1 class="my-5 section-header">OTHER BRANDS</h1>
        <div class="row row-cols-2 row-cols-lg-6 row-cols-md-3 g-2 ">
          <div class="col  ">
            <div class="p-3 shadow text-center">
              <a href="#">
                <p className="pfnt fs-3">Apple</p>
              </a></div>
          </div>

          <div class="col  ">
            <div class="p-3 shadow text-center">
              <a href="#">
                <p className="pfnt fs-3">Samsung</p>
              </a></div>
          </div>
          <div class="col  ">
            <div class="p-3 shadow text-center">
              <a href="#">
                <p className="pfnt fs-3">Oneplus</p>
              </a></div>
          </div>
          <div class="col  ">
            <div class="p-3 shadow text-center">
              <a href="#">
                <p className="pfnt fs-3">Redmi</p>
              </a></div>
          </div>
          <div class="col  ">
            <div class="p-3 shadow text-center">
              <a href="#">
                <p className="pfnt fs-3">Mi</p>
              </a></div>
          </div>
          <div class="col  ">
            <div class="p-3 shadow text-center">
              <a href="#">
                <p className="pfnt fs-3">Poco</p>
              </a></div>
          </div>

          <div class="col  ">
            <div class="p-3 shadow text-center">
              <a href="#">
                <p className="pfnt fs-3">Oppo</p>
              </a></div>
          </div>

          <div class="col  ">
            <div class="p-3 shadow text-center">
              <a href="#">
                <p className="pfnt fs-3">Vivo</p>
              </a></div>
          </div>

          <div class="col  ">
            <div class="p-3 shadow text-center">
              <a href="#">
                <p className="pfnt fs-3">Renoo</p>
              </a></div>
          </div>

          <div class="col  ">
            <div class="p-3 shadow text-center">
              <a href="#">
                <p className="pfnt fs-3">IQOO</p>
              </a></div>
          </div>

          <div class="col  ">
            <div class="p-3 shadow text-center">
              <a href="#">
                <p className="pfnt fs-3">Xiaomi</p>
              </a></div>
          </div>

          <div class="col  ">
            <div class="p-3 shadow text-center">
              <a href="#">
                <p className="pfnt fs-3">Infinix</p>
              </a></div>
          </div>

          <div class="col  ">
            <div class="p-3 shadow text-center">
              <a href="#">
                <p className="pfnt fs-3">Tecno</p>
              </a></div>
          </div>

          <div class="col  ">
            <div class="p-3 shadow text-center">
              <a href="#">
                <p className="pfnt fs-3">Google</p>
              </a></div>
          </div>

        </div>
        <hr></hr>
      </div>









    </div>

  );
}
export default Mobilebrand;