import Card from 'react-bootstrap/Card';
import "./Ourcard.css";
import mobile from "../assets/images/mb4.jpg";
import water from "../assets/images/waterbottle.jpg";
import ilusion from "../assets/images/ilusion lamp.jpg";
import snap from "../assets/images/snapbook.jpg";
import wall from "../assets/images/wall decar.jpg";
import photo from "../assets/images/photostand.jpg";
import mugs from "../assets/images/mugs.jpg";
import tshirt from "../assets/images/tshirt.jpg";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from "react-router-dom";

function Ourcard() {
  const navigate = useNavigate();
  return (
    <div >
      <Container fluid >
        <div>
          <h1 class="section-header section-header" childre="heading" style={{ textAlign: "center", padding: "5px", paddingTop: "20px", paddingBottom: "30px" }}>Our product</h1>
        </div>
        <div>
          {/* <Row>
            <Col xs={12} lg={4} md={12} sm={12} >
            <Card className='m-2' style={{ height: "550px" }}>
                <div style={{ height: "350px" }}>
                  <Card.Img className="crdimg" variant="top" src={mobile} />
                </div>
                <Card.Body>
                  <Card.Title className='title fs-3'
                  >Mobile Case</Card.Title>
                  <Card.Text >
                    <li>Hardcase - Rs.299/-</li>
                    <li>Softcase - Rs.399/</li>
                    <li>Glasscase - Rs.499/</li>
                  </Card.Text>
                  <button className='but1' onClick={() => navigate("/Mobile")}>View</button>
                </Card.Body>
              </Card>
            </Col>


            <Col xs={12} lg={4} md={12} sm={12}>
              <Card className='m-2' style={{ height: "550px" }}>
                <div style={{ height: "350px" }}>
                  <Card.Img className="crdimg" variant="top" src={water} />
                </div>
                <Card.Body>
                  <Card.Title className='title fs-3'>Cuboid Chains</Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make up the
                    bulk of the card's content.
                  </Card.Text>
                  <button className='but1'>DETAILS</button>
                </Card.Body>
              </Card></Col>

            <Col xs={12} lg={4} md={12} sm={12} >
              <Card className='m-2' style={{ height: "550px" }}>
                <div style={{ height: "350px" }}>
                  <Card.Img className="crdimg" variant="top" src={ilusion} />
                </div>
                <Card.Body>
                  <Card.Title className='title fs-3'>Sipper Bottles</Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make up the
                    bulk of the card's content.
                  </Card.Text>
                  <button className='but1'>DETAILS</button>
                </Card.Body>
              </Card></Col>
          </Row> */}
           <Row>
            <Col xs={12} lg={4} md={12} sm={12} >
              <Card className='m-2'>
                <div style={{ height: "350px" }}>
                <Card.Img className="crdimg" variant="top" src={mobile} />
                </div>
                <Card.Body className='crdbody'>
                <Card.Title className='title fs-3'
                  >Mobile Case</Card.Title>
                  <Card.Text >
                    <li>Hardcase - Rs.299/-</li>
                    <li>Softcase - Rs.399/</li>
                    <li>Glasscase - Rs.499/</li>
                  </Card.Text>
                  <button className='but1'onClick={() => navigate("/Mobile")}>View</button>
                </Card.Body>
              </Card>
            </Col>


            <Col xs={12} lg={4} md={12} sm={12}><Card className='m-2'>
              <div style={{ height: "350px" }}>
              <Card.Img className="crdimg" variant="top" src={water} />
              </div>
              <Card.Body>
                <Card.Title className='title fs-3'>Snapbooks</Card.Title>
                <Card.Text>
                  Some quick example text to build on the card title and make up the
                  bulk of the card's content.
                </Card.Text>
                <button className='but1'>DETAILS</button>
              </Card.Body>
            </Card>
            </Col>

            <Col xs={12} lg={4} md={12} sm={12} >
              <Card className='m-2'>
                <div style={{ height: "350px" }}>
                <Card.Img className="crdimg" variant="top" src={ilusion} />
                </div>
                <Card.Body>
                  <Card.Title className='title fs-3'>Wall Decoratives</Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make up the
                    bulk of the card's content.
                  </Card.Text>
                  <button className='but1'>DETAILS</button>
                </Card.Body>
              </Card></Col>
          </Row>
        </div>

        <div className='m-2'>
          <Row>
            <Col xs={12} lg={4} md={12} sm={12} >
              <Card className='m-2'>
                <div style={{ height: "350px" }}>
                  <Card.Img className="crdimg" variant="top" src={snap} />
                </div>
                <Card.Body className='crdbody'>
                  <Card.Title className='title fs-3'>illusion Lamp</Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make up the
                    bulk of the card's content.
                  </Card.Text>
                  <button className='but1'>DETAILS</button>
                </Card.Body>
              </Card>
            </Col>


            <Col xs={12} lg={4} md={12} sm={12}><Card className='m-2'>
              <div style={{ height: "350px" }}>
                <Card.Img className="crdimg" variant="top" src={wall} />
              </div>
              <Card.Body>
                <Card.Title className='title fs-3'>Snapbooks</Card.Title>
                <Card.Text>
                  Some quick example text to build on the card title and make up the
                  bulk of the card's content.
                </Card.Text>
                <button className='but1'>DETAILS</button>
              </Card.Body>
            </Card>
            </Col>

            <Col xs={12} lg={4} md={12} sm={12} >
              <Card className='m-2'>
                <div style={{ height: "350px" }}>
                  <Card.Img className="crdimg" variant="top" src={photo} />
                </div>
                <Card.Body>
                  <Card.Title className='title fs-3'>Wall Decoratives</Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make up the
                    bulk of the card's content.
                  </Card.Text>
                  <button className='but1'>DETAILS</button>
                </Card.Body>
              </Card></Col>
          </Row>
        </div>

        <div className='m-2'>
          <Row>
            <Col xs={12} lg={4} md={12} sm={12} >
              <Card className='m-2'>
                <div style={{ height: "350px" }}>
                  <Card.Img className="crdimg" variant="top" src={mugs} />
                </div>
                <Card.Body className='crdbody'>
                  <Card.Title className='title fs-3'>Photo Stands</Card.Title>
                  <Card.Text>
                    Some quick example text to build on the card title and make up the
                    bulk of the card's content.
                  </Card.Text>
                  <button className='but1'>DETAILS</button>
                </Card.Body>
              </Card>
            </Col>


            <Col xs={12} lg={4} md={12} sm={12}><Card className='m-2'>
              <div style={{ height: "350px" }}>
                <Card.Img className="crdimg" variant="top" src={tshirt} />
              </div>
              <Card.Body>
                <Card.Title className='title fs-3'>Mugs</Card.Title>
                <Card.Text>
                  Some quick example text to build on the card title and make up the
                  bulk of the card's content.
                </Card.Text>
                <button className='but1'>DETAILS</button>
              </Card.Body>
            </Card>
            </Col>

            <Col xs={12} lg={4} md={12} sm={12} ><Card className='m-2'>
              <div style={{ height: "350px" }}>
                <Card.Img className="crdimg" variant="top" src={ilusion} />
              </div>
              <Card.Body>
                <Card.Title className='title fs-3'>T-shirts</Card.Title>
                <Card.Text>
                  Some quick example text to build on the card title and make up the
                  bulk of the card's content.
                </Card.Text>
                <button className='but1'>DETAILS</button>
              </Card.Body>
            </Card>
            </Col>

            <Col xs={12} lg={4} md={12} sm={12} ><Card className='m-2'>
              <div style={{ height: "350px" }}>
                <Card.Img className="crdimg" variant="top" src={ilusion} />
              </div>
              <Card.Body>
                <Card.Title className='title fs-3'>Pop Sockets</Card.Title>
                <Card.Text>
                  Some quick example text to build on the card title and make up the
                  bulk of the card's content.
                </Card.Text>
                <button className='but1'>DETAILS</button>
              </Card.Body>
            </Card>
            </Col>

            <Col xs={12} lg={4} md={12} sm={12} ><Card className='m-2'>
              <div style={{ height: "350px" }}>
                <Card.Img className="crdimg" variant="top" src={ilusion} />
              </div>
              <Card.Body>
                <Card.Title className='title fs-3'>Fridge Magnets</Card.Title>
                <Card.Text>
                  Some quick example text to build on the card title and make up the
                  bulk of the card's content.
                </Card.Text>
                <button className='but1'>DETAILS</button>
              </Card.Body>
            </Card>
            </Col>

            <Col xs={12} lg={4} md={12} sm={12} ><Card className='m-2'>
              <div style={{ height: "350px" }}>
                <Card.Img className="crdimg" variant="top" src={ilusion} />
              </div>
              <Card.Body>
                <Card.Title className='title fs-3'>Office Stationary</Card.Title>
                <Card.Text>
                  Some quick example text to build on the card title and make up the
                  bulk of the card's content.
                </Card.Text>
                <button className='but1'>DETAILS</button>
              </Card.Body>
            </Card>
            </Col>

          </Row>
        </div>

      </Container>
    </div>
  );
}
export default Ourcard;




