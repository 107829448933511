import Ourproduct from "../Component/Ourpoduct";
import Ourcard from "../Home/Ourcard";
import "./Printshopy.css";
import Banner from "./Banner";
import Ourvision from "./Ourvision";
import Video from "./Video";
// import Socialmedia from "../Component/Socialmedia";

function Printshopy() {
  return (
    <>
      <div class="container-fluid ">
        <div class="row ">
          <div class=" col-sm-12 col-md-4 col-lg-2  col-xs-12 stick">
            <Ourproduct />
          </div>
          <div class="col-sm-12 col-md-8 col-lg-9 col-xl-10 col-xs-12 ">
            <Banner />
            <Ourcard />
            <Video />
            {/* <Ourvision /> */}
            
            {/* <Socialmedia /> */}
          </div>
        </div>
      </div>
    </>
  );
}
export default Printshopy;