import "./Glasscase.css";
import { useNavigate } from "react-router-dom";

function Glasscase() {
  const navigate = useNavigate();

  return (
    <div className="col">
      <div class="card" >
        <div class=" embed-responsive embed-responsive-16by9">
          <iframe class="vdifrmgc embed-responsive-item" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" allowfullscreen></iframe>
        </div>
        <div class="card-body">
          <h5 class="card-title">GLASS CASE</h5>
          <p class="card-text">@ Just RS 499/-</p>

          <a href="#" class="btn btn-primary"  onClick={() => navigate("/Csdesigntype")}>Shop Now</a>
        </div>
      </div>
    </div>
  );
}
export default Glasscase;