import Ourproduct from "../../Component/Ourpoduct";
import Selectimg from "../uploadimage/Selectimg";

function Upload() {
  return (
    <div class="container-fluid w-full">
      <div class="row ">
        <div class="col-sm-12 col-md-4 col-lg-2  stick">
          <Ourproduct />
        </div>
        <div class="col-sm-12 col-md-8 col-lg-10 column">
          <Selectimg />
        </div>
      </div>
    </div>
  );
}
export default Upload;