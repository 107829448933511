import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import "./Head.css";
import "../Pages/Login";
import "../Pages/Sign";

function Head() {
  return (
    <Navbar collapseOnSelect expand="lg" className=" classy ">
      <Container>
        <Navbar.Brand href="#home">
          <h1 className=' me-auto d-lg-none  d-sm-block d-md-block '>PRINTSHOPPY</h1>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto fw-bold ">
            <Nav.Link href="#features">Contact Us</Nav.Link>
            <Nav.Link href="#features">FAQ's</Nav.Link>
            <Nav.Link href="#pricing">Track Order</Nav.Link>
          </Nav>
          <Navbar.Brand className=" me-auto d-none d-md-none  d-lg-block " href="#home">
            <h1>PRINTSHOPPY</h1>
          </Navbar.Brand>
          <Nav className=" fw-bold ">
            <Nav.Link href="/Login">Login</Nav.Link>
            <Nav.Link href="/Sign">Register</Nav.Link>
            <Nav.Link eventKey={2} href="#memes">
              <i class="bi bi-cart fs-30"></i>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Head;