import Ourproduct from "../../Component/Ourpoduct";
// import Selectimg from "../uploadimage/Selectimg";
import Ordermbcase  from "./Ordermbcase";

function Upload() {
  return (
   
    <div class="container-fluid ">
    <div class="row ">
      <div class=" col-sm-12 col-md-4 col-lg-2  col-xs-12  stick">
        <Ourproduct />
      </div>
      <div class="col-sm-12 col-md-8 col-lg-9 col-xl-10 col-xs-12 ">
<Ordermbcase/>
      </div>
    </div>
  </div>
  );
}
export default Upload;