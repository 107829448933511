import "./Steps.css";

function Steps() {
    return (
        <div className="container-fluid">
            <div class="row row-cols-1 row-cols-lg-3 row-cols-md-2 text-center  g-2 g-lg-3">
                <div className="col">
                    <h2 >step1</h2>
                  
                    <i class="bi bi-hand-index-thumb-fill fs-1"></i>
                    <p>Select Your Design</p>
                </div>
                <div className="col ">
                    <h2>step2</h2>
                    <i class="bi bi-upload fs-1"></i>
                    <p>Upload Your Photo/Image</p>
                    <p></p>
                </div>
                <div className="col">
                    <h2>step3</h2>
                    <i class="bi bi-bag-check-fill fs-1"></i>
                    <p>Preview & Order Your Case</p>
                </div>
            </div>
        </div>
    )
}
export default Steps;