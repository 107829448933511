import Cscarddesign from "../../Component/Cscarddesign";
import React, { useState, useEffect } from 'react';

function Csdesign() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <div>
      <div>
        <h1 class="section-header">mobile version name</h1>
      </div>
      <div className="text-center">
        <p>Choose a case that suits best for your phone & select a design to upload pic.</p>
      </div>
      <div class="container-fluid border ">
        <div class="m-2 row row-cols-2 row-cols-lg-6 g-2 g-lg-3">
          <div class="col">
            <div class="p-3 border">
              <h5>Filters</h5>
            </div>
          </div>
          <div class="col">
            <div class="p-3 border bg-light">
              <h5>All Designs</h5>
            </div>
          </div>
          <div class="col">
            <div class="p-3 border bg-light"><h5>Only Text</h5></div>
          </div>
          <div class="col">
            <div class="p-3 border bg-light"><h5>Single Pic</h5></div>
          </div>
          <div class="col">
            <div class="p-3 border bg-light"><h5>Pic With Text</h5></div>
          </div>
          <div class="col">
            <div class="p-3 border bg-light"><h5>Multi Pic</h5></div>
          </div>
        </div>
      </div>
      <div class="container-fluid my-4">
        <div class="row row-cols-1 row-cols-lg-3 g-2 g-lg-3">
          <Cscarddesign />
        </div>
      </div>
    </div>
  )
}
export default Csdesign;