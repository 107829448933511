function Productdetails() {
    return (
        <div class="my-5">
            <h1 class="section-header">Product details</h1>
            <div style={{ textIndent: "30px" ,textAlign:"justify"}}>
                Buy Photo-Printed Customisable Back Covers for
                All Smartphone and Tablet Models Online from the
                comfort of your home at yourPrint. Put your Photo on
                the back cover of your phone and make it unique. You
                can also use our pre-designed templates to Design Your
                Own Cover. This is the best place to Customize Phone
                Cases Online in India. You can choose any photo to print
                on the back covers. The covers are available for over 1000
                phone models in Hard Plastic, Soft Silicone,
                Leather Flip Covers, Mirror Flip Covers and more varieties.
                You can add your own Text, Images, Quotes,
                Logo and more on the back covers.
            </div>
        </div>
    );
}
export default Productdetails;