import React from "react";
import mbcs from "../../assets/images/mbcs.png";
import "./Selectimg.css";
import { useNavigate } from "react-router-dom";


function Selectimg() {
  const navigate = useNavigate();
  return (
    <div className="border container-fluid rounded my-lg-5 my-sm-2 my-xs-2 my-md-4 p-2">
      <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 ">
        <div className="row border m-2 ">
          <div className="design col col-lg-12 col-sm-12 col-xs-12 col-md-12">
            <i className=" bi bi-arrow-clockwise reficon p-3"></i>
            <i className="bi bi-paint-bucket reficon p-3"></i>
            <button className="fnshbut" onClick={() => navigate("/Ordercase")}>Finish design</button>
          </div>
        </div>
        <div className="row border m-2">
          <div className=" border  col  col-lg-8 col-sm-12 col-xs-12 col-md-12 order-lg-1 order-2  text-center">
            <div className="w-50 height-auto m-auto p-2">
              <img className="caseimg" src={mbcs} alt="Selected Image" />
            </div>
          </div>
          <div className="border p-2 col-12 order-lg-2  order-1 col-lg-4 col-sm-12 col-xs-12 col-md-12">
            <div>
              <p>
                <i className="bi bi-fullscreen reficon"></i>
                &nbsp;&nbsp;&nbsp; backgroundcolor
              </p>
              <p>
                <i className="bi bi-fonts reficon"></i>
                &nbsp;<input />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Selectimg;
